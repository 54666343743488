import * as React from "react"
import Layout from "../components/Layout";
import localize from "../components/localize";
import {graphql} from "gatsby";
import Landing from "../components/Landing";
import SEO from "../components/SEO";
import Arrow from "../components/Arrow";
import Angebote from "../components/Angebote";
import Kunden from "../components/Kunden";
import Vita from "../components/Vita";
import CallToAction from "../components/CallToAction";
import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "../components/MyPortableText";

export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        mehrEntdecken{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        download{
        _type
        en
        de
        }
    }
    }
    allSanityStartseite {
    edges {
      node {
        title {
          en
          de
          _type
        }
        quote {
          en
          de
          _type
        }
        image {
          asset {
            gatsbyImageData(
            placeholder: NONE
            )
          }
        }
        angebote {
          slug {
            current
          }
            _rawSubheadline
          _rawTextoverview
          _rawTitle
          _rawTags
          pageactive
          icon{ asset {gatsbyImageData}}
        }
        kundenlogos {
          asset {
            gatsbyImageData
          }
        }
        testimonials {
          _rawText
          _rawJob
          name
        }
        linkedincv
        vitaheadline {
          _type
          de
          en
        }
        logocolor
        vitaimage {
          asset {
            gatsbyImageData(
            placeholder: NONE
            )
          }
        }
        vitaquote {
          en
          de
          _type
        }
        vitasubheadline {
          en
          de
          _type
        }
        vitasignature { asset { gatsbyImageData }}
        _rawVitatext
        _rawCtatext
        ctalink1text{
            en
            de
            _type
        }
        ctalink1
        ctalink2text{
            en
            de
            _type
        }
        ctalink2
        
      }
    }
  }
        allSanityBook{
            edges{
                node{
                    title{
                        _type
                        en
                        de
                    }
                    _rawDescription
                    image{
                        asset{
                            gatsbyImageData
                        }
                    }
                }
            }
        }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
      }
     }
     }
     allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
    }
   `;

const TheRecruitmentBookPage = ({data, location}) => {

    const page = data.allSanityStartseite.edges[0].node;
    const book = data.allSanityBook.edges[0].node;

    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

  return (
      <div id={"top"}>
          <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
              <SEO title={'The Recruitment Book'} customImage={true} imageUrl={'https://cdn.sanity.io/images/9mke0w2g/production/5626ca6f26399adf8b09a766620344d8e273489b-1080x1080.jpg?w=1080&h=1080&auto=format'} description={'Dies ist ihre Chance, einer der ersten Testleser von The Recruitment Book zu werden. Bei Interesse kontaktieren Sie mich über LinkedIn oder E-Mail an office@barbara-stampf.at, und ich sende Ihnen ein kostenloses Vorab-Exemplar als PDF.'} />
                <div className="container book__outer">
                    <div className="inner book__inner">
                        <div className={"text"} style={{maxWidth: '600px'}}>
                            <h1 className={"uppercase color-blue"}>
                                {book?.title}
                            </h1>
                            <MyPortableText value={book?._rawDescription}></MyPortableText>
                        </div>
                        <div className={"image"}>
                            <GatsbyImage objectFit={"contain"} className={""} image={book.image?.asset?.gatsbyImageData} alt={book?.title}/>
                        </div>
                    </div>

                </div>
          </Layout>
      </div>
  )
}

export default localize(TheRecruitmentBookPage)

//
